import React, {useEffect, useState} from 'react';
import {Accordion, Button, Card, Container, Table} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {getAllProjects} from "../http/projectAPI";

const Main = ({user, handleNotification, setLoader}) => {
    const navigate = useNavigate()
    const [projects, setProjects] = useState([]);

    const getProjectsWorker = () => {
        setLoader({status: true, message: 'Получение проектов'});
        getAllProjects()
            .then((data) => {
                setProjects([...data]);
            })
            .catch(err => {
                handleNotification(err?.response?.data?.message || err.message, 'danger');
            })
            .finally(() => setLoader(null));
    }

    useEffect(() => {
        getProjectsWorker();
    }, []);

    return (
        <Container>
            {
                <Button className={'mt-2 mb-3'} onClick={() => navigate('/project/new')}>
                    Создать проект
                </Button>
            }

            {projects.length === 0 ?
                <h3>Проекты отсутствуют</h3> :
                <div className={'d-flex justify-content-around flex-row flex-wrap'}>
                    {projects.map(el =>
                        <Card key={el.name + el.id} style={{width: '18rem'}} className={'mb-2'}>
                            <Card.Img variant={"top"} src={process.env.REACT_APP_API_PUBLIC_URL + el.image}/>
                            <Card.Body>
                                <Card.Title>{el.name}</Card.Title>
                                <Card.Text>{el.description.length > 150 ? `${el.description.slice(0, 150)}...` : el.description}</Card.Text>
                                <div className={'mb-2'}>
                                    <span>Ссылка: <a href={`https://${el.subdomain}.tglnk.fun`}
                                                     target={"_blank"}>https://{el.subdomain}.tglnk.fun</a></span>
                                </div>
                                <Button
                                    variant={'primary'}
                                    onClick={() => navigate(`/project/${el.subdomain}`)}
                                >
                                    Редактирование
                                </Button>
                            </Card.Body>
                        </Card>
                    )}
                </div>
            }
        </Container>
    );
};

export default Main;